import { database, storage } from '../firebase/config';
import { addDoc, collection, Timestamp } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';


export const useAddArticle = (user) => {
    const uploadArticle = async (article) => {
        try {
            console.log("Starting article upload...");  // 🛠 Debugging log
            
            // Upload Cover Image
            let coverImageUrl = "";
            if (article.coverImage) {
                console.log("Uploading cover image...");
                const coverPath = `coverImages/${article.title}_cover`;
                const coverRef = ref(storage, coverPath);
                const coverSnapshot = await uploadBytes(coverRef, article.coverImage);
                coverImageUrl = await getDownloadURL(coverSnapshot.ref);
                console.log("Cover image uploaded:", coverImageUrl);
            }
    
            // Upload Additional Images in Parallel
            console.log("Uploading additional images...");
            const imageUploadPromises = article.images.map((image, index) => {
                const imagePath = `articleImages/${article.title}_${index}`;
                const imageRef = ref(storage, imagePath);
                return uploadBytes(imageRef, image).then(snapshot => getDownloadURL(snapshot.ref));
            });
    
            const imageUrls = await Promise.all(imageUploadPromises);
            console.log("All images uploaded:", imageUrls);
    
            // Create Article Object
            const newArticle = {
                title: article.title,
                subtitle: article.subtitle,
                tags: article.tags || [],
                coverImageUrl,
                images: imageUrls,
                paragraphs: article.paragraphs,
                descriptions: article.descriptions,
                language: article.language,
                createdAt: Timestamp.now(),
                authorId: article.authorId,
            };
    
            console.log("Saving article to Firestore:", newArticle);
            const collectionRef = collection(database, 'articles');
            await addDoc(collectionRef, newArticle);
            console.log("Article successfully saved to Firestore! 🚀");
    
        } catch (e) {
            console.error("Error uploading article:", e.message);
        }
    };

    return { uploadArticle };
};