import { useParams } from 'react-router-dom';
import { useFetchData } from '../hooks/useFetchData';

const DetailedArticle = () => {
  const { id } = useParams(); // Get the article ID from the URL
  const { documents: articles } = useFetchData('articles');

  // Find the article by ID
  const article = articles?.find((item) => item.id === id);

  if (!article) return <p>Loading...</p>; // Show loading message if the article is not found

  return (
    <section className="p-6">
      {/* Cover Image */}
      {article.coverImageUrl && (
        <img
          src={article.coverImageUrl}
          alt={article.title}
          className="w-full h-60 object-cover rounded-lg mt-4"
        />
      )}

      {/* Title & Subtitle */}
      <h1 className="text-3xl font-bold mt-4">{article.title}</h1>
      <p className="text-white-600">{article.subtitle}</p>

      {/* Loop through arrays and display content in order */}
      <div className="mt-6 space-y-6">
        {article.paragraphs.map((para, index) => (
          <div key={index}>
            {/* Paragraph */}
            <p className="text-white-800 mb-2" dangerouslySetInnerHTML={{ __html: para }}></p>

            {/* Image (if exists for this index) */}
            {article.images?.[index] && (
              <div className="mt-2">
                <img
                  src={article.images[index]}
                  alt={`Image ${index + 1}`}
                  className="w-64 h-64 object-cover rounded-lg"
                />
              </div>
            )}
            {article.descriptions?.[index] && (
              <p className="text-white-600 text-sm mt-2">{article.descriptions[index]}</p>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default DetailedArticle;
