import React, { useEffect, useState, useContext } from 'react';
import Loading from '../components/Loading';
import { useAddArticle } from '../hooks/useAddArticle';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from 'yup';
import { CustomSelect } from '../components/CustomSelect';
import {UserContext} from '../App';


const tags = [
    { label: 'Critics', value: 'Critics' },
    { label: 'Reccomendations', value: 'Reccomendations' },
    { label: 'Valami0', value: 'Valami0' },
    { label: 'Valami1', value: 'Valami1' },
    { label: 'Valami2', value: 'Valami2' },
    { label: 'Valami3', value: 'Valami3' },
    { label: 'Valami4', value: 'Valami4' }
];




const NewArticle = () => {
    const { user } = useContext(UserContext);
    const { uploadArticle } = useAddArticle(user);

    const [title, setTitle] = useState("");
    const [subtitle, setSubtitle] = useState("");
    const [paragraphs, setParagraphs] = useState([]);
    const [coverImage, setCoverImage] = useState(null);
    const [images, setImages] = useState([]);
    const [descriptions, setDescriptions] = useState([]);
    const [tag, setTags] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");
    const [newParagraph, setNewParagraph] = useState("");
    const [newTag, setNewTag] = useState("");
    const [newDescription, setNewDescription] = useState("");
    const [language, setLanguage] = useState("hu");


    const schema = yup.object().shape({
        title: yup.string().required("Please provide the title"),
        subtitle: yup.string().required("Please provide the subtitle"),
    });

    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const addParagraph = () => {
        if (newParagraph.trim()) {
            setParagraphs([...paragraphs, newParagraph]);
            setNewParagraph("");
        }
    };

    const addImageDescription = () => {
        if (newDescription.trim()) {
            setDescriptions([...descriptions, newDescription]);
            setNewDescription("");
        }
    };

    const removeItem = (index, type) => {
        if (type === "paragraph") setParagraphs(paragraphs.filter((_, i) => i !== index));
        if (type === "description") setDescriptions(descriptions.filter((_, i) => i !== index));
        if (type === "image") setImages(images.filter((_, i) => i !== index));
    };

    const handleCoverImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            setCoverImage(file);
            setErrorMessage("");
        }
    };

    const handleAddTag = () => {
        if (newTag.trim() !== "") {
            setTags([...tags, newTag.trim()]); // Add new tag to the tags array
            setNewTag(""); // Clear input after adding
        }
    };

    const handleImageUpload = (event) => {
        const file = event.target.files[0]; // Only take a single file at a time
        if (!file) return;
    
        if (images.length >= 4) {
            setErrorMessage("You can only upload up to 4 additional images.");
            return;
        }
    
        setImages([...images, file]); // Add new image one by one
        setErrorMessage("");
    };
    
    // Move image up in the order
    const moveImageUp = (index) => {
        if (index === 0) return; // Already at the top
        const newImages = [...images];
        [newImages[index - 1], newImages[index]] = [newImages[index], newImages[index - 1]];
        setImages(newImages);
    };

    // Move image down in the order
    const moveImageDown = (index) => {
        if (index === images.length - 1) return; // Already at the bottom
        const newImages = [...images];
        [newImages[index], newImages[index + 1]] = [newImages[index + 1], newImages[index]];
        setImages(newImages);
    };

    // Remove an image from the list
    const removeImage = (index) => {
        setImages(images.filter((_, i) => i !== index));
    };

    const handleUpload = async (formData) => {
        if (!coverImage) {
            setErrorMessage("Please upload a cover image.");
            return;
        }
        try {
            const article = {
                title: formData.title,
                subtitle: formData.subtitle,
                tag,
                coverImage,
                images,
                paragraphs,
                descriptions,
                language,
                authorId: user.uid,
            };

            await uploadArticle(article);

            setParagraphs([]);
            setCoverImage(null);
            setImages([]);
            setDescriptions([]);
            setLanguage("hu");
            setTags([]);
            setErrorMessage("");
        } catch (e) {
            console.error("Error uploading article:", e.message);
        }
    };

    return (
        <section className="flex flex-col items-center justify-center">
            <h1 className="my-8 text-xl font-bold">Upload New Article</h1>
            <form onSubmit={handleSubmit(handleUpload)} className="w-[90%] max-w-lg flex flex-col gap-4">

                {/* Cover Image */}
                <label>Cover Image (Required)</label>
                <input type="file" accept="image/*" onChange={handleCoverImageUpload} />
                {coverImage && <p className="text-green-600">Cover image added ✅</p>}

                {/* Additional Images */}
                <label>Additional Images (Max 4)</label>
                <input type="file" multiple accept="image/*" onChange={handleImageUpload} />
                {errorMessage && <p className="text-red-500">{errorMessage}</p>}
                
                {/* Display Images with Controls */}
                <div className="mt-4">
                    {images.map((img, index) => (
                        <div key={index} className="flex items-center gap-2 mt-2">
                            <img src={URL.createObjectURL(img)} alt={`Preview ${index}`} className="w-20 h-20 object-cover rounded" />
                            
                            {/* Move Up Button */}
                            <button onClick={() => moveImageUp(index)} disabled={index === 0} className="bg-gray-300 px-2 py-1 rounded">⬆️</button>

                            {/* Move Down Button */}
                            <button onClick={() => moveImageDown(index)} disabled={index === images.length - 1} className="bg-gray-300 px-2 py-1 rounded">⬇️</button>

                            {/* Remove Image */}
                            <button onClick={() => removeImage(index)} className="bg-red-500 text-white px-2 py-1 rounded">❌</button>
                        </div>
                    ))}
                </div>
                
                <label>Title</label>
                <input 
                    type="text" 
                    {...register("title")} 
                    placeholder="Article Title" 
                    value={title} 
                    onChange={(e) => setTitle(e.target.value)}
                />
                {errors?.title && <p className="text-red-500">{errors.title.message}</p>}

                {/* Subtitle Input */}
                <label>Subtitle</label>
                <input 
                    type="text" 
                    {...register("subtitle")} 
                    placeholder="Article Subtitle" 
                    value={subtitle} 
                    onChange={(e) => setSubtitle(e.target.value)}
                />
                {errors?.subtitle && <p className="text-red-500">{errors.subtitle.message}</p>}
                {/* Tags */}
                    <label>Tags</label>
                    <CustomSelect isMulti={true} onSelect={(selected) => setTags(selected.map(tag => tag.value) || [])} options={tags} />
                    <label>Additional tag</label>
                    <textarea
                        placeholder="Additional tags"
                        value={newTag}
                        onChange={(e) => setNewTag(e.target.value)}
                        rows="1"
                    />
                    <button type="button" onClick={handleAddTag} className="bg-blue-500 text-white px-2 py-1 rounded">
                        Add Tag
                    </button>

                {/* Paragraphs */}
                <label>New Paragraph</label>
                <textarea
                    placeholder="New Paragraph"
                    value={newParagraph}
                    onChange={(e) => setNewParagraph(e.target.value)}
                    rows="4"
                />
                <button type="button" onClick={addParagraph} className="bg-blue-500 text-white px-2 py-1 rounded">Add Paragraph</button>

                {/* Image Descriptions */}
                <label>New Picture Description</label>
                <textarea
                    placeholder="Image Description"
                    value={newDescription}
                    onChange={(e) => setNewDescription(e.target.value)}
                    rows="3"
                />
                <button type="button" onClick={addImageDescription} className="bg-blue-500 text-white px-2 py-1 rounded">Add Description</button>

                <label className="font-bold">Language of article:</label>
                <div className="flex items-center gap-4 mt-2">
                    <label>
                        <input 
                            type="radio" 
                            name="language" 
                            value="hu" 
                            checked={language === "hu"} 
                            onChange={() => setLanguage("hu")} 
                        />
                        Hungarian (HU)
                    </label>
                    <label>
                        <input 
                            type="radio" 
                            name="language" 
                            value="en" 
                            checked={language === "en"} 
                            onChange={() => setLanguage("en")} 
                        />
                        English (EN)
                    </label>
                </div>

                <button type="submit" className="bg-green-500 text-white p-2 rounded">Upload Article</button>
            </form>
        {/* Render Article Preview */}
        <div className="mt-8 w-[90%] max-w-lg">
            {coverImage&& (
                <img src={URL.createObjectURL(coverImage)} alt="Cover" className="w-full h-40 object-cover rounded-md" />
            )}
            
            {/* Title & Subtitle */}
            <h1 className="text-3xl font-bold mt-4">{title}</h1>
            <p className="text-gray-600">{subtitle}</p>

            {/* Loop through arrays and display content in order */}
            <div className="mt-6 space-y-6">
                {paragraphs.map((para, index) => (
                    <div key={index}>
                        {/* Paragraph */}
                        <p className="text-gray-800" dangerouslySetInnerHTML={{ __html: para }}></p>

                        {/* Image (if exists for this index) */}
                        {images?.[index] && (
                            <div className="mt-2">
                                 <img src={URL.createObjectURL(images[index])} alt="Cover" className="w-full h-40 object-cover rounded-md" />
                            </div>
                        )}
                        {descriptions?.[index] && (
                            <p className="text-gray-600 text-sm mt-2">{descriptions[index]}</p>
                        )}
                    </div>
                ))}
            </div>
        </div>
        </section>
    );
};

export default NewArticle;