import { CountdownTimer, CurrentRace, RaceState } from './CountdownTimer';
import { GraphFieldRace } from './GraphFieldRace';
import { PosterContainer, PosterContainerSmall } from './PosterContainer';
import { DemoTitle } from './DemoTitle';
import { UserIcon, UserIconList } from './UserIcon';
import { DemoDescription } from './DemoDescription';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ArticleCard = ({ article }) => { 
    console.log("Article data in Card:", article);
    return (
      <div>
        <h2>
          {/* Link to the detailed page using the article ID */}
          <Link to={`/detailed-article/${article.id}`} className="text-blue-500 hover:text-blue-700">
            {article.title}
          </Link>
        </h2>
        <p>{article.subtitle}</p>
      </div>
    );
};
  
export default ArticleCard;