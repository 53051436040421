/*
title
cover_image
cover_image_description
author
    ->profil_pic
    ->first and last name
creation_date (datetime)
tags(multiple choice(?)) (stringarray)
//3 ARRAYS
-PARAGRAPHS
-TITLES
-PICTURES
-->image, descriptions and source
TLDR
views (int)
comments (number) //fosros hugyos
megosztás // url a near-nallkan/articles/cím+id
//további képek max. 9
*/
//tagek szerinti filterezésstb.

import React, { useEffect, useState } from 'react';
import ArticleCard from '../components/ArticleCard.jsx';
import { useFetchData } from '../hooks/useFetchData';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Articles = () => {
  const { documents: articles } = useFetchData('articles');
  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <section id="gridRow sectionTitle" className="flex flex-row flex-wrap justify-start items-center">
        <div className="flex flex-col items-start gap-2.5 px-2 pt-2.5 pb-4 self-stretch">
          <h1 className="font-h1-primetitle text-4xl text-nbgreenmain underline underline-offset-4 leading-3 decoration-2 decoration-nbgreylight decoration-wavy">
            Articles
          </h1>
        </div>
      </section>
      {/* Display all articles */}
      {articles && articles.map((article) => (
        <ArticleCard
          key={article.id} // Use the article's ID as the key for each article card
          article={article}
        />
      ))}
    </>
  );
};

export default Articles;
